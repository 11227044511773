import api from "@/util/api";

export default {
    namespaced: true,

    data: {
        result: null,
        error: null,
        loading: false,
    },

    getters: {
        result: (state) => state.result,
        error: (state) => state.error,
        loading: (state) => state.loading,
    },

    mutations: {
        setResult: (state, result) => {
            state.result = result;
        },

        setLoading: (state, loading) => {
            state.loading = loading;
        },

        setError: (state, error) => {
            state.error = error;
        }
    },

    actions: {
        fetchCalc: (store, data) => {
            store.commit('setResult', []);
            store.commit('setLoading', true);

            api.request().post('/api/v1/calc', data).then((response) => {
                if (response.data.success) {
                    store.commit('setError', null);
                    store.commit('setResult', response.data);
                } else {
                    store.commit('setError', response.data.message);
                }

                store.commit('setLoading', false);
            }).catch((error) => {
                store.commit('setError', 'Ошибка при загрузке.');
                store.commit('setLoading', false);
            })
        },

        resetCalc: (store) => {
            store.commit('setResult', undefined);
            store.commit('setLoading', true);
            store.commit('setError', null);
        }
    }
}