export const loadSuggestions = (prompt) => {
    return axios.get(route('dadata.suggestion'), {
        params: {prompt}
    }).then(data => data.data)
        .then((data) => {
            return data.map(item => ({
                title: item.value,
                value: item.value,
                data: item.data,
            }))
        })
}

export const convertAddress = (address) => {
    const data = address.data;
    const result = {};

    result.country = data.country;
    result.region = data.region;
    result.city = data.city;
    result.street = data.street;
    result.house = data.house;
    result.fias_id = data.fias_id;
    result.kladr_id = data.kladr_id;
    result.postal_code = data.postal_code;

    result.latitude = data.geo_lat;
    result.longitude = data.geo_lon;

    return result
}