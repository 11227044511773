import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import Notifications from 'notiwind'
// import VueApexCharts from 'vue3-apexcharts'
import VueClipboard from 'vue3-clipboard'

import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { store } from './store';
import './util/price';

import VueTheMask from 'vue-the-mask'
import * as Sentry from "@sentry/vue";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} | Ручная работа`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(Notifications)
            .use(store)
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(VueTheMask)
            .use(VueClipboard, {
                autoSetContainer: true,
                appendToBody: true,
            });

        if (import.meta.env.APP_ENV != "local") {
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN,
                environment: import.meta.env.APP_ENV,
            });
        }

        // app.use(VueApexCharts);
        // app.component('apexchart', VueApexCharts);

        return app.mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
