import api from "@/util/api";

export default {
    namespaced: true,

    state () {
        return {
            sales: null,
            salesLoading: true,
            salesError: null,

            totals: {},
            totalsLoading: true,
            totalsError: null,
        }
    },

    getters: {
        sales: (state) => state.sales,
        salesLoading: (state) => state.salesLoading,
        salesError: (state) => state.salesError,

        totals: (state) => state.totals,
        totalsLoading: (state) => state.totalsLoading,
        totalsError: (state) => state.totalsError,
    },

    mutations: {
        setSales (state, sales) {
            state.sales = sales;
        },

        setSalesLoading (state, loading) {
            state.salesLoading = loading;
        },

        setSalesError (state, error) {
            state.salesError = error;
        },

        setTotals (state, totals) {
            state.totals = totals;
        },

        setTotalsLoading (state, loading) {
            state.totalsLoading = loading;
        },

        setTotalsError (state, error) {
            state.totalsError = error;
        }
    },

    actions: {
        fetchSales (store, params) {
            store.commit('setSales', []);
            store.commit('setSalesError', null);
            store.commit('setSalesLoading', true);

            api.request().get('/api/v1/seller/stat/sales', {params}).then((response) => {
                if (response.data.success) {
                    store.commit('setSales', response.data.data);
                } else {
                    store.commit('setSalesError', response.data.message);
                }

                store.commit('setSalesLoading', false);
            }).catch((error) => {
                store.commit('setSalesError', 'Ошибка при загрузке.');
                store.commit('setSalesLoading', false);
            })
        },

        fetchTotals (store, params) {
            store.commit('setTotals', []);
            store.commit('setTotalsError', null);
            store.commit('setTotalsLoading', true);

            api.request().get('/api/v1/seller/stat/totals', {params}).then((response) => {
                if (response.data.success) {
                    store.commit('setTotals', response.data.data);
                } else {
                    store.commit('setTotalsError', response.data.message);
                }

                store.commit('setTotalsLoading', false);
            }).catch((error) => {
                store.commit('setTotalsError', 'Ошибка при загрузке.');
                store.commit('setTotalsLoading', false);
            })
        }
    },
}