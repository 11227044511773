import { setupAxios } from "./axios";

const api = {
    /**
   * Return an axios instance configured to make requests to Nova's API
   * and handle certain response codes.
   */
  request(options) {
    let axios = setupAxios()

    if (options !== undefined) {
      return axios(options)
    }

    return axios
  }
}

export default api;