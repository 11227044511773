import { createStore } from 'vuex'
import { default as calc } from './modules/calc'
import { default as cart } from './modules/cart'
import { default as stat } from './modules/stat'
import { default as pstat } from './modules/pstat'

import { default as order } from './modules/manager/order'

export const store = createStore({
    modules: {
        calc,
        cart,
        stat,
        pstat,

        manager: {
            modules: {
                order
            }
        },
    }
})

export default store